<template>
  <div>
    <v-card
      color="#fff"
      class="project-list-card"
    >
      <v-col class="text-right table-top-border">
        <v-btn
          color="#000"
          @click="sendEmergencyAlert()"
        >
          Send Alert
        </v-btn>
      </v-col>
      <v-card-title class="filter-card">
        <v-row>
          <v-col
            cols="12"
            lg="4"
            md="5"
            sm="12"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
              :hint="isSearchAdded ? searchHint : ''"
              @keyup.enter="handleSearch"
              @keydown.esc="handleSearchClear"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="alertHistories"
        :search="search"
        class="elevation-1 table-checkin"
        mobile-breakpoint="100"
        :items-per-page="itemsPerPage"
        :server-items-length="totalRecords"
        :footer-props="{
          'items-per-page-options': [10, 20, 30],
        }"
        :options.sync="options"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td
              class="more-link"
              @click="showResponse(item.id)"
            >
              {{ item.title }}
            </td>
            <td>{{ item.description }}</td>
            <td>{{ item.created_at | convertToLocal }}</td>
            <td>
              <font class="success-style">
                Send*
              </font>
            </td>
            <td>
              <v-btn
                class="mt-n2 action-btn"
                elevation="1"
                fab
                x-small
                icon
                color="#37474F"
                @click="showResponse(item.id)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner :loading="loading" />
    <send-emergency-msg-landing
      v-if="showAlertSendForm"
      @closed="showAlertSendForm = false"
    />
  </div>
</template>
<script>
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import SendEmergencyMsgLanding from './SendEmergencyMsgLanding.vue';
import moment from 'moment';
import global from 'src/mixins/global';

export default {
  name: 'EmergencyMessages',
  components: {
    'centre-spinner': spinner,
    'send-emergency-msg-landing': SendEmergencyMsgLanding,
  },
  filters: {
    convertToLocal (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
    },
  },
  mixins: [global],
  data () {
    return {
      search: null,
      showAlertSendForm: false,
      loading: false,
      headers: [
        { text: 'Alert Title', align: 'start', value: 'title' },
        { text: 'Description', value: 'description' },
        { text: 'Send At', value: 'created_at' },
        { text: 'Status', value: 'status' },
        { text: 'Responses', value: 'more' },
      ],
      page: 1,
      itemsPerPage: 10,
      pageStart: 0,
      options: {},
    };
  },
  computed: {
    isSearchAdded () {
      if (this.search === null || this.search === '') {
        return false;
      }
      return true;
    },
    alertHistories () {
      const list = this.$store.getters['widgets/getAlertHistories'];
      if (list.length === 0) {
        return;
      }
      const sortedList = list.data.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      return sortedList;
    },
    paginatedalertHistories () {
      return this.$store.getters['widgets/getAlertHistories'];
    },
    prePaginationNumber () {
      return this.$store.getters['records/getPrePaginationNumber'];
    },
    totalRecords () {
      if (Object.keys(this.paginatedalertHistories).length > 0) {
        return this.paginatedalertHistories.total;
      }
      return 0;
    },
    searchHint () {
      return `Press Enter to filter by name: '${this.search}' or press ESC to clear.`;
    },
  },
  watch: {
    options: {
      async handler () {
        await this.paginatedCall();
      },
      deep: true,
    },
  },
  async mounted () {
    await this.checkPreRoute();
    await this.getAlertHistories();
  },
  methods: {
    async checkPreRoute () {
      const preRouteName = this.$router.history.current.meta.previousRoute.name;
      if ((Object.keys(this.paginatedalertHistories).length !== 0) && preRouteName === 'Alert Responses') {
        this.options.page = this.prePaginationNumber;
      } else {
        await this.getAlertHistories();
      }
    },
    async handleSearchClear () {
      this.pageStart = 0;
      this.options.page = 1;
      this.search = null;
      await this.getAlertHistories();
    },
    async handleSearch () {
      this.pageStart = 0;
      this.options.page = 1;
      await this.getAlertHistories();
    },
    async getAlertHistories () {
      this.loading = true;
      await this.$store.dispatch('widgets/fetchAlertHistories', {
        params: {
          title: this.search === '' ? null : this.search,
          limit: this.itemsPerPage,
          startPage: this.pageStart,
        },
      }).catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
    sendEmergencyAlert () {
      this.showAlertSendForm = true;
    },
    getLatestAlertHistories () {
      setTimeout(() => {
        this.$store.dispatch('widgets/fetchAlertHistories');
      }, 3000);
    },
    showResponse (alertId) {
      this.$router.push(`widgets/alerts/${alertId}/responses`);
    },
    async paginatedCall () {
      this.itemsPerPage = this.options.itemsPerPage;
      await this.$store.dispatch('records/savePrePaginationNumber', this.options.page);
      this.pageStart = this.options.page * this.itemsPerPage - this.itemsPerPage;
      this.getAlertHistories();
    },
  },
};
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}

.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}

.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}

.v-data-table {
  background-color: #ECEFF1;
}

.title-style {
  color: #37474F;
}

.icon-style {
  color: #37474F;
  font-size: 45px;
}

.success-style {
  color: #2E7D32;
  font-weight: bold;
  font-size: 12px;
}

.more-link {
  cursor: pointer;
}

.alert-style {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}

.table-checkin {
  border-radius: 0px !important;
  background: #fff !important;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}

.table-checkin tbody tr:nth-of-type(even) {
  background: #fff !important;
}

.table-checkin tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}

.v-card {
  margin-top: 0px !important;
}

.action-btn {
  background: #fff !important;
}

.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}

.table-top-border {
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}

.info-text {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.sub-title-style {
  color: #aaa;
}

.project-list-card {
  box-shadow: none !important;
}
</style>
