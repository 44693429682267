<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <h2 class="title-style pt-3">
      Widgets 📢
    </h2>
    <p class="sub-title-style">
      Explore Widgets
    </p>
    <v-tabs
      v-model="widgetTabs"
      class="mt-5"
    >
      <v-tab>
        <v-icon class="mr-2">
          mdi-message-alert-outline
        </v-icon>Emergency Alerts
      </v-tab>
      <v-tab>
        <v-icon class="mr-2">
          mdi-account-question-outline
        </v-icon>Suspicious Check-Ins
      </v-tab>
      <v-tab>
        <v-icon class="mr-1">
          mdi-account-arrow-right-outline
        </v-icon>Manual Check-Outs <span
          v-if="(subscriptionCheck('trial'))"
          class="premium-container"
        >
          <v-icon class="premium-style">
            mdi-crown-circle
          </v-icon>
        </span>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="widgetTabs">
      <v-tab-item>
        <emergency-messages />
      </v-tab-item>
      <v-tab-item>
        <suspicious-checkins />
      </v-tab-item>
      <v-tab-item>
        <manual-checkouts />
      </v-tab-item>
    </v-tabs-items>
    <div>
      <no-widgets v-if="shownNoWidgets" />
      <centre-spinner
        :loading="loading"
      />
    </div>
  </v-container>
</template>

<script>
import EmergencyMessages from './emergencymessages/EmergencyMessages';
import SuspiciousCheckIns from './suspiciouscheckIn/SuspiciousCheckIns';
import ManualCheckOuts from './manualcheckout/ManualCheckOuts.vue';
import global from 'src/mixins/global';
import ModuleHelper from 'src/helpers/module-helper';
import Constants from 'src/constants';
import NoWidgets from './NoWidgets.vue';
import spinner from 'src/views/dashboard/component/SpinnerCentre';

 export default {
    name: 'Widgets',
    components: {
      'emergency-messages': EmergencyMessages,
      'suspicious-checkins': SuspiciousCheckIns,
      'manual-checkouts': ManualCheckOuts,
      'no-widgets': NoWidgets,
      'centre-spinner': spinner,
    },
    mixins: [global],
    data: () => ({
      selectedItem: null,
      showEmergencyMessages: false,
      showSuspisiousChecIns: false,
      showManualCheckOuts: false,
      helper: new ModuleHelper(),
      shownNoWidgets: false,
      loading: false,
      widgetTabs: null,
    }),

    computed: {
      isMobile () {
        if (this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl) {
          return false;
        } else {
          return true;
        }
      },
    },
    async mounted () {
      this.loading = true;
      await this.isManualCheckOutModuleExist();
      await this.isEmergencyAlertModuleExist();
      await this.isSuspiciousCheckInsModuleExist();
      await this.checkAndShowSubModules();
      this.loading = false;
    },
    methods: {
      checkAndShowSubModules () {
        if (this.isEmergencyAlertModuleExist === false && this.isSuspiciousCheckInsModuleExist === false && this.isManualCheckOutModuleExist === false) {
          this.shownNoWidgets = true;
      } else if (this.isEmergencyAlertModuleExist) {
        this.showEmergencyMessages = true;
        this.selectedItem = 0;
      } else if (this.isSuspiciousCheckInsModuleExist) {
        this.getSuspiciousCheckIns();
      } else {
        this.getManualCheckoutUsers();
      }
      },
      getEmergencyMessages () {
        this.showSuspisiousChecIns = false;
        this.showManualCheckOuts = false;
        this.showEmergencyMessages = true;
      },
      isManualCheckOutModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_WIDGETS, Constants.SUB_MODULE_MANUAL_CHECKOUTS));
      },
      isSuspiciousCheckInsModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_WIDGETS, Constants.SUB_MODULE_SUSPICIOUS_CHECKINS));
      },
      isEmergencyAlertModuleExist () {
        return (this.helper.isSubModuleExist(Constants.MODULE_WIDGETS, Constants.SUB_MODULE_EMERGENCY_ALERTS));
      },
      getSuspiciousCheckIns () {
        this.showEmergencyMessages = false;
        this.showManualCheckOuts = false;
        this.showSuspisiousChecIns = true;
      },
      getManualCheckoutUsers () {
        this.showEmergencyMessages = false;
        this.showSuspisiousChecIns = false;
        this.showManualCheckOuts = true;
      },
    },
  };

</script>
<style scoped>
.nav-title-style {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  color: #37474F;
  font-size: 25px;
}
.alert-style {
  color: #C62828;
  font-size: 25px;
  text-align: center;
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
}
.premium-style {
  font-size:25px;
  color:#006883!important;
  font-weight:bold;
}
.premium-container{
  background-color: #ffffff!important;
  border-radius: 50%;
}
.title-style{
  font-size: 36px;
  font-weight: 600;
}
.sub-title-style{
  color: #aaa;
}
</style>
