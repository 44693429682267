<template>
  <v-container class="container">
    <div
      class="alert-style"
      width="80%"
    >
      <v-icon>
        mdi-alert-circle-outline
      </v-icon>
      No Widgets are available
    </div>
  </v-container>
</template>

  <script>

   export default {
      name: 'NoWidgets',
   };

  </script>
<style scoped>
.alert-style{
    margin: auto;
    align-items: center;
    display: flex;
    flex-direction: column;
}
.container{
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
