import { walkTreeData } from 'he-tree-vue';

export function getDefaultStructure (appUsers) {
    const defaultStructure = [{ text: 'All App Users', children: [] }];
    const allAppUsers = appUsers;
    const structure = defaultStructure;
    for (const appUser of allAppUsers) {
        structure[0].children.push({
            id: appUser.external_id,
            text: `${appUser.first_name} ${appUser.last_name} - ${appUser.company.name}`,
        });
    }
    return structure;
};

export function addMissingAppUsers (treeData, appUsers) {
    const availableAppUsers = [];
    walkTreeData(treeData, (node) => {
        if (node.id !== undefined) {
            availableAppUsers.push(node.id);
        }
    });
    const newAppUsers = appUsers.filter(i => !availableAppUsers.includes(i.external_id));
    for (const newAppUser of newAppUsers) {
        treeData[0].children.push({
            id: newAppUser.external_id,
            text: `${newAppUser.first_name} ${newAppUser.last_name} - ${newAppUser.company.name}`,
        });
    }
    return treeData;
};
