<template>
  <v-dialog
    v-model="showModal"
    max-width="600"
    persistent
  >
    <v-card
      outlined
      color="#fff"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-calendar-clock
        </v-icon> Filter Records
      </h2>
      <br>
      <v-form>
        <v-container fluid>
          <v-card-text class="text-body-1 text-center">
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                  v-model="projectId"
                  :items="projectsList"
                  item-text="name"
                  item-value="id"
                  label="Project"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-datetime-picker
                  v-model="from"
                  label="From"
                  date-format="yyyy-MM-dd"
                  time-format="HH:mm"
                  :text-field-props="{
                    suffix: userTimeZone,
                    class: 'custom-label-color',
                    outlined: true,
                    dense: true,
                  }"
                  :date-picker-props="{
                    headerColor: '#37474F',
                    max: maxStartDate
                  }"
                  :time-picker-props="{
                    headerColor: '#37474F'
                  }"
                >
                  <template v-slot:dateIcon>
                    <v-icon
                      large
                      color="#37474F"
                    >
                      mdi-calendar
                    </v-icon>
                  </template>
                  <template v-slot:timeIcon>
                    <v-icon
                      large
                      color="#37474F"
                    >
                      mdi-alarm
                    </v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-datetime-picker
                  v-model="to"
                  label="To"
                  :text-field-props="{
                    persistentHint: true,
                    suffix: userTimeZone,
                    outlined: true,
                    dense: true,
                  }"
                  :date-picker-props="{
                    headerColor: '#37474F',
                    min: minEndDate,
                    max: maxStartDate
                  }"
                  :time-picker-props="{
                    headerColor: '#37474F'
                  }"
                >
                  <template v-slot:dateIcon>
                    <v-icon
                      large
                      color="#37474F"
                    >
                      mdi-calendar
                    </v-icon>
                  </template>
                  <template v-slot:timeIcon>
                    <v-icon
                      large
                      color="#37474F"
                    >
                      mdi-alarm
                    </v-icon>
                  </template>
                </v-datetime-picker>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="#000"
              depressed
              default
              @click="submit()"
            >
              Filter
            </v-btn>
            <v-btn
              class="mt-2"
              color="#aaa"
              depressed
              default
              @click="cancel()"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner :loading="loading" />
  </v-dialog>
</template>

<script>
import moment from 'moment-timezone';
import spinner from 'src/views/dashboard/component/SpinnerCentre';
export default {
  name: 'FilterSuspiciousCheckIns',
  components: {
    'centre-spinner': spinner,
  },
  data: () => ({
    from: null,
    to: null,
    projectId: null,
    showModal: true,
    maxStartDate: moment().format('YYYY-MM-DD'),
    loading: false,
  }),

  computed: {
    projectsList () {
      return this.$store.getters['projects/getProjectsList'];
    },
    minEndDate () {
      return moment(this.from).format('YYYY-MM-DD');
    },
    userTimeZone () {
      var Zone = moment.tz.guess();
      var timezone = moment.tz(Zone).zoneAbbr();
      return timezone;
    },
    recordsFilteringParams () {
      return this.$store.getters['widgets/getSuspiciousCheckinsFilters'];
    },
    isAnyFilterUsed () {
      if (Object.keys(this.recordsFilteringParams).length !== 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  watch: {
    showModal: function (newval, oldval) {
      if (newval === false) {
        this.$emit('closed');
      }
    },
  },
  async mounted () {
    this.loading = true;
    await this.getProjectsList();
    await this.checkAnyFilterUsed();
    this.loading = false;
  },
  methods: {
    cancel () {
      this.showModal = false;
    },
    async getProjectsList () {
      await this.$store.dispatch('projects/fetchProjectsList').catch(() => {
        this.loading = false;
      });
    },
    async submit () {
      this.loading = true;
      const data = {
        from: this.from,
        to: this.to,
        projectId: this.projectId,
      };
      this.$store.dispatch('widgets/storeSuspiciousCheckinsFilters', data);
      this.$emit('dataToFilter');
      this.showModal = false;
    },
    checkAnyFilterUsed () {
      if (this.isAnyFilterUsed) {
        this.to = this.recordsFilteringParams.to;
        this.from = this.recordsFilteringParams.from;
        this.projectId = this.recordsFilteringParams.projectId;
      }
    },
  },
};
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 21px;
}

.icon-style {
  color: #37474F;
  font-size: 55px;
  margin-right: 5px;
}
</style>
