<template>
  <div>
    <div v-if="subscriptionCheck('active')">
      <v-card
        color="#fff"
        class="project-list-card"
      >
        <v-col class="text-right table-top-border">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                fab
                dark
                outlined
                color="#37474F"
                small
                class="btn-style"
                v-on="on"
                @click="clearFilterManualCheckOuts()"
              >
                <v-icon
                  small
                  dark
                >
                  mdi-filter-off
                </v-icon>
              </v-btn>
            </template>
            <span>ALL Records</span>
          </v-tooltip>
          <v-badge
            v-if="filterCount !== 0"
            color="red"
            :content="filterCount"
            class="count-badge"
          />
          <v-btn
            color="#000"
            @click="showFilterForm = true"
          >
            Filter
          </v-btn>
        </v-col>
        <v-card-title class="filter-card">
          <v-row>
            <v-col
              cols="12"
              lg="4"
              md="5"
              sm="12"
            >
              <v-text-field
                v-model="search"
                label="Search"
                append-icon="mdi-magnify"
                outlined
                dense
                :hint="isSearchAdded ? searchHint : ''"
                @keyup.enter="handleSearch"
                @keydown.esc="handleClearSearch"
              />
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          :headers="manualCheckOut.headers"
          :items="manualCheckOuts"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          item-key="id"
          disable-sort
          class="elevation-1 table-checkin"
          mobile-breakpoint="100"
          :server-items-length="totalRecords"
          :footer-props="{
            'items-per-page-options': [10, 20, 30],
          }"
          :options.sync="options"
        >
          <template v-slot:item="{ item, expand, isExpanded }">
            <tr>
              <td>{{ item.checkin_record.app_user.first_name }} {{ item.checkin_record.app_user.last_name }}</td>
              <td>{{ item.checkin_record.app_user.company.name }}</td>
              <td>
                {{ item.checkin_record.project.name }}
              </td>
              <td>
                {{ item.checkin_record.checkout_time | convertToLocal }}
              </td>
              <td>{{ item.created_at | convertToLocal }}</td>
              <td>
                <v-btn
                  icon
                  small
                  @click.stop
                  @click="expand(!isExpanded)"
                >
                  <v-icon v-if="!isExpanded">
                    mdi-arrow-down-drop-circle
                  </v-icon>
                  <v-icon v-if="isExpanded">
                    mdi-arrow-up-drop-circle
                  </v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-card>
                <v-toolbar
                  color="#37474F"
                  dark
                >
                  <v-toolbar-title class="my-tool-bar-title-style">
                    Manual Check-Out Reason
                  </v-toolbar-title>
                </v-toolbar>
                <v-list>
                  <v-list-item-content class="ml-3">
                    <v-list-item-title>
                      <span class="manual-reason">{{ item.reason }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list>
              </v-card>
            </td>
          </template>
        </v-data-table>
        <div class="py-3" />
      </v-card>
      <centre-spinner :loading="loading" />
      <filter-manual-check-outs
        v-if="showFilterForm"
        @closed="showFilterForm = false"
        @dataToFilter="filteredData"
      />
    </div>
    <div v-else>
      <v-alert
        text
        prominent
        type="warning"
        color="#006883"
        border="left"
        class="alert-style"
      >
        Upgrade to Premium Version to view the CheckIns.
      </v-alert>
    </div>
  </div>
</template>
<script>
import spinner from 'src/views/dashboard/component/SpinnerCentre';
import moment from 'moment';
import FilterManualCheckOuts from './FilterManualCheckOuts.vue';
import global from 'src/mixins/global';

export default {
  name: 'ManualCheckOuts',
  components: {
    'centre-spinner': spinner,
    'filter-manual-check-outs': FilterManualCheckOuts,
  },
  filters: {
    convertToLocal (stringDatetime) {
      return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
    },
  },
  mixins: [global],
  data () {
    return {
      search: null,
      loading: false,
      manualCheckOut: {
        headers: [
          { text: 'Name', align: 'start', value: 'checkin_record.app_user.first_name' },
          { text: 'Company Name', value: 'name' },
          { text: 'Project', value: 'project' },
          { text: 'Recorded Check-Out', value: 'checkout_time' },
          { text: 'Actual Check-Out', value: 'created_at' },
          { text: 'More', value: 'more' },
        ],
      },
      showFilterForm: false,
      expanded: [],
      singleExpand: true,
      from: null,
      to: null,
      projectId: null,
      page: 1,
      filterIsOn: false,
      options: {},
      itemsPerPage: 10,
      pageStart: 0,
    };
  },
  computed: {
    paginatedRecordsStructure () {
      return this.$store.getters['widgets/getManualCheckOuts'];
    },
    checkInRecords () {
      const records = this.paginatedRecordsStructure;
      return records.data;
    },
    totalRecords () {
      if (Object.keys(this.paginatedRecordsStructure).length > 0) {
        return this.paginatedRecordsStructure.total;
      }
      return 0;
    },
    manualCheckOuts () {
      const list = this.$store.getters['widgets/getManualCheckOuts'];
      if (list.length === 0) {
        return;
      }
      const sortedList = list.data.sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      return sortedList;
    },
    recordsFilteringParams () {
      return this.$store.getters['widgets/getManualCheckOutFilters'];
    },
    isSearchAdded () {
      if (this.search === null || this.search === '') {
        return false;
      }
      return true;
    },
    searchHint () {
      return `Press Enter to filter by name: '${this.search}' or press ESC to clear.`;
    },
    filterCount () {
      var count = 0;
      if (Object.values(this.recordsFilteringParams).length === 0) {
        return 0;
      } else {
        Object.values(this.recordsFilteringParams).filter((item) => {
          if (item !== null) {
            count++;
          }
        });
        return count;
      }
    },
  },
  watch: {
    options: {
      async handler () {
        await this.paginatedCall();
      },
      deep: true,
    },
  },
  async mounted () {
    await this.$store.dispatch('widgets/storeManualCheckoutFilters', {});
  },
  methods: {
    handleSearch () {
      this.options.page = 1;
      this.pageStart = 0;
      this.getManualCheckOuts();
    },
    handleClearSearch () {
      this.options.page = 1;
      this.search = null;
      this.pageStart = 0;
      this.getManualCheckOuts();
    },
    async getManualCheckOuts () {
      this.loading = true;
      await this.$store.dispatch('widgets/fetchManualCheckOuts', {
        params: {
          limit: this.itemsPerPage,
          startPage: this.pageStart,
          name: this.search === '' ? null : this.search,
          from: this.from,
          to: this.to,
          projectId: this.projectId,
        },
      }).catch(() => {
        this.loading = false;
      });
      this.loading = false;
    },
    async filteredData () {
      this.from = this.recordsFilteringParams.from;
      this.to = this.recordsFilteringParams.to;
      this.projectId = this.recordsFilteringParams.projectId;
      this.pageStart = 0;
      this.options.page = 1;
      await this.getManualCheckOuts();
    },
    async clearFilterManualCheckOuts () {
      this.search = null;
      this.from = null;
      this.to = null;
      this.projectId = null;
      this.options.page = 1;
      this.options.itemsPerPage = 10;
      await this.$store.dispatch('widgets/storeManualCheckoutFilters', {});
      await this.getManualCheckOuts();
    },
    async paginatedCall () {
      this.itemsPerPage = this.options.itemsPerPage;
      this.pageStart = this.options.page * this.itemsPerPage - this.itemsPerPage;
      await this.getManualCheckOuts();
    },
  },
};
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}

.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}

.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}

.v-data-table {
  background-color: #ECEFF1;
}

.title-style {
  color: #37474F;
}

.icon-style {
  color: #37474F;
  font-size: 45px;
  margin-left: 20px;
}

.type {
  text-transform: capitalize;
}

.btn-style {
  margin-right: 15px;
}

.manual-reason {
  font-weight: bold;
  color: #37474F;
}

.alert-style {
  font-family: 'Times New Roman', Times, serif;
  font-size: 16px;
}

.table-checkin {
  border-radius: 0px !important;
  background: #fff !important;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}

.table-checkin tbody tr:nth-of-type(even) {
  background: #fff !important;
}

.table-checkin tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}

.v-card {
  margin-top: 0px !important;
}

.action-btn {
  background: #fff !important;
}

.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}

.table-top-border {
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}

.info-text {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.sub-title-style {
  color: #aaa;
}

.project-list-card {
  box-shadow: none !important;
}

.count-badge {
  z-index: 1;
  position: absolute;
  right: 20px;
  top: 20px;
}
</style>
