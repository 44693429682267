<template>
  <v-card
    outlined
    color="#ECEFF1"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-phone-message
      </v-icon> Alert Message
    </h2>
    <v-form
      ref="form"
      v-model="valid"
    >
      <v-container fluid>
        <v-card-text class="text-body-1">
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="messageContent.title"
                label="Message Title*"
                dense
                outlined
                hint="Title for your reference-'Emergency Alert'."
                persistent-hint
                :rules="[(v) => !!v || 'This field is required']"
              />
            </v-col>
          </v-row>
          <br>
          <v-textarea
            v-model="messageContent.description"
            filled
            label="Message Descripion*"
            :counter="100"
            counter-value-color="red"
            :rules="[v => (v && v.length < 100) || 'Description must be 100 characters or less']"
          />
          <p class="text-sm-left mt-5">
            *Indicates required field
          </p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            class="mt-2"
            color="#aaa"
            @click="cancelSending()"
          >
            <span class="black--text">Cancel</span>
          </v-btn>
          <v-btn
            class="mt-2"
            color="#000"
            @click="selectAppUsers()"
          >
            Next
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-form>
    <centre-spinner
      :loading="loading"
    />
  </v-card>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import Constants from 'src/constants';

  export default {
    name: 'AddMessageContents',
    components: {
      'centre-spinner': spinner,
    },
    data () {
      return {
        messageContent: {
            title: '',
            description: null,
        },
        valid: false,
        loading: false,
      };
    },
    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },
    methods: {
        selectAppUsers () {
          if (this.$refs.form.validate() === false) {
            this.$store.dispatch('alert/onAlert', {
              message: 'Please fill the required fields before proceeding.',
              type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            return;
          }
          this.loading = true;
          setTimeout(() => {
            this.$emit('message-added', this.messageContent);
          }, 1000);
        },
        cancelSending () {
          this.$emit('sending-cancelled');
        },
    },
  };
</script>
<style scoped>
.title-style {
  color: #37474F;
  margin-left: 30px;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.doc-btn-style {
  background-color: #FF3700 !important;
}
.my-chip-style {
  margin-top: 20px;
}
</style>
